<template>
  <div class="modal-footer">
    <b-button v-if="!isSubmitting" type="button" class="btn btn-secondary" @click="cancel()">{{ $t('Cancel') }}</b-button>
    <b-overlay :show="isSubmitting"
               spinner-small
               spinner-variant="primary"
               class="d-inline-block">
      <b-button :disabled="isSubmitting"
                variant="primary"
                @click="submit()">
        {{ $t('Save') }}
      </b-button>
    </b-overlay>
  </div>
</template>
<script>
  export default {
    name: 'ModalFooterButton',
    props: {
      setIsSubmitting: Boolean,
      isVueModal: Boolean
    },
    methods: {
      cancel() {
        if (this.$router.currentRoute.meta.isModal) {
          let currentModalId = this.$store.getters['Modal/currentModalId']
          this.$bvModal.hide(currentModalId)
        }
        else if (this.isVueModal) {
          this.$modal.hide('vue-modal');
        }
        else {
          this.$router.go(-1)
        }
      },
      submit() {
        this.$emit('submit')
      }
    },
    computed: {
      isSubmitting() { return this.setIsSubmitting }
    }
  }
</script>


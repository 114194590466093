<template>
  <div class="iq-card-body-billdetail">
    <b-row v-if="totalEntityCount != 1">
      <b-col md="12">
        <hr v-if="bdIndex+1 > 1" />
        <div class="ribbon ribbon--clip ribbon--left ribbon--primary">
          <div class="ribbon__target">
            <span class="ribbon__inner" /> <i class="fa fa-hashtag"></i> &nbsp;  {{ $t("Line") }}&nbsp;{{ bdIndex+1 }}
          </div>
        </div>
        <br />
        <br />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="11">
        <b-row>
          <b-col md="3" class="mb-0">
            <label class="ml-1 mb-0"><font-awesome-icon icon="fa-solid fa-user" />&nbsp;{{ $t('Staff') }}</label>
            <ValidationProvider :name="$t('Staff')" rules="required" v-slot="{ errors }">
              <v-select transition="" v-model="bd.staffId"
                        :reduce="s => s.id" label="name"
                        :options="staffList"
                        @change="rowStaffChanged(bd)"
                        :class="(errors.length > 0 ? ' is-invalid' : '')"
                        required>
                <template v-slot:no-options>
                  {{$t('NoMatchingOptionsMessage')}}
                </template>
              </v-select>
              <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
            </ValidationProvider>
          </b-col>

          <b-col md="7" v-if="isRowStaffSelected" class="mb-0">
            <label class="ml-1 mb-0"><font-awesome-icon icon="fa-solid fa-box" />&nbsp;{{ $t('Service') + ' / ' + $t('Product')  + ' / ' + $t('Package') }}</label>
            <ValidationProvider :name="$t('Service') + ' / ' + $t('Product')  + ' / ' + $t('Package')" rules="required" v-slot="{ errors }">
              <v-select transition="" v-model="entityKey"
                        :reduce="s => `${s.entityType},${s.id}`" label="name"
                        :filterBy="$root.filterOptions"
                        :searchable="true"
                        :options="entityList"
                        :class="(errors.length > 0 ? ' is-invalid' : '')"
                        required>
                <template v-slot:option="option">
                  <font-awesome-icon :icon="option.icon" />
                  {{ option.name }}
                </template>
                <template v-slot:no-options>
                  {{$t('NoMatchingOptionsMessage')}}
                </template>
              </v-select>
              <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
            </ValidationProvider>
          </b-col>

          <b-col md="2" v-if="isRowEntitySelected" class="mb-0">
            <label class="ml-1 mb-0"><font-awesome-icon icon="fa-solid fa-square-caret-up" />&nbsp;{{ $t('Quantity') }}</label>
            <ValidationProvider :name="$t('Quantity')" rules="required" v-slot="{ errors }">
              <b-form-input v-model="bd.quantity" type="text" value="1" @keypress="$root.isNumber"
                            :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
              <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
            </ValidationProvider>
          </b-col>
        </b-row>

        <b-row v-if="isRowEntitySelected">
          <b-col md="3" class="mb-0">
            <label class="ml-1 mb-0"><font-awesome-icon icon="fa-solid fa-badge-percent" />&nbsp;{{ $t('Discount') }}</label>
            <b-form-input v-model="bd.userInputDiscount" type="text" :disabled="canApplyDiscount" @keypress="$root.isNumber"></b-form-input>
          </b-col>

          <b-col md="3" class="mb-0 d-flex justify-content-center align-items-end">
            <b-form-checkbox v-model="bd.useCreditPrice" switch inline size="lg">
              <span style="font-size:15px !important; vertical-align:middle !important">{{ $t('PriceWithCard') }}</span>
            </b-form-checkbox>
          </b-col>

          <b-col md="3" class="mb-0">
            <label class="ml-1 mb-0"><font-awesome-icon icon="fa-solid fa-globe" />&nbsp;{{ $t('Rate') }}</label>
            <v-select transition="" v-model="bd.currencyId"
                      :clearable="false"
                      :reduce="s => s.currencyId" label="symbol"
                      :options="parent.currencyList"
                      :disabled="rowTotalDisabled">
              <template v-slot:no-options>
                {{$t('NoMatchingOptionsMessage')}}
              </template>
            </v-select>
          </b-col>

          <b-col md="3" class="mb-0">
            <label class="ml-1 mb-0"><font-awesome-icon icon="fa-solid fa-coins" />&nbsp;{{ $t('Price') }}</label>
            <ValidationProvider :name="$t('Price')" rules="required" v-slot="{ errors }">
              <b-form-input v-model="bd.rowTotal" type="text" @keypress="$root.isNumber"
                            :class="(errors.length > 0 ? ' is-invalid' : '')" :disabled="rowTotalDisabled"></b-form-input>
              <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
            </ValidationProvider>
          </b-col>
        </b-row>
      </b-col>

      <b-col md="1" class=" d-flex justify-content-center align-items-center">
        <b-button variant="danger" @click="removeEntity(bd)">
          <font-awesome-icon icon="fa-regular fa-xmark" />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import staffService from '../../services/staff'
  import entityService from '../../services/entity'

  export default {
    name: 'BillDetail',
    props: {
      bdIndex: Number,
      initialBd: Object,
      entityCount: Number
    },
    data() {
      return {
        entityList: [{}],
        staffList: [],
        entityKey: ''
      }
    },
    methods: {
      removeEntity(bd) {
        this.$emit('removeEntity', bd);
      },
      getEntityList(staffId) {
        let url = '/entity/listJson?servicesStaffId=' + staffId;

        if (this.bd && this.bd.entityId) {
          url += `&currentEntityType=${this.bd.entityType}&currentEntityId=${this.bd.entityId}`;
        }

        // SORULACAK !!!!!!!!!
        //if (this.parent.allowSellOutOfStockProduct) {
        //  url += "&allowSellOutOfStockProduct=true"
        //}
        //else {
        //  url += "&allowSellOutOfStockProduct=false"
        //}

        entityService.getEntityList(url).then(response => { this.entityList = response; });
      },
      rowStaffChanged() {
        this.getEntityList(this.bd.staffId)
      },
      getStaffList() {
        let paramId = (this.bd && this.bd.staffId) ? { params: { id: this.bd.staffId } } : {};

        staffService.getStaffList(paramId).then(response => { this.staffList = response; });
      },
      calculateRowPrice() {
        let originalUsePrice = (this.bd.useCreditPrice == true ? this.bd.entity.basePriceWithCard : this.bd.entity.basePrice);
        let quantitiedPrice = (originalUsePrice * this.bd.quantity);

        if (this.bd.userInputDiscount && this.bd.userInputDiscount.toString().startsWith('%')) {

          let inputDiscount = this.bd.userInputDiscount.toString().split('%')[1];
          if (!isNaN(inputDiscount)) {
            this.bd.discount = (((quantitiedPrice * inputDiscount) / 100) * -1);
            this.bd.discountRate = inputDiscount / 100;
            quantitiedPrice = quantitiedPrice - ((quantitiedPrice * inputDiscount) / 100);
            this.bd.rowTotal = quantitiedPrice;
          }
        }
        else if (this.bd.userInputDiscount && !isNaN(this.bd.userInputDiscount)) {
          this.bd.discount = (this.bd.userInputDiscount * -1);
          this.bd.rowTotal = (quantitiedPrice - this.bd.userInputDiscount);
          this.bd.discountRate = ((100 * this.bd.userInputDiscount) / quantitiedPrice) / 100;
        }
        else {
          this.bd.rowTotal = quantitiedPrice;
        }
      },
    },
    watch: {
      'bd.quantity': {
        handler: function (val) {
          this.bd.quantity = val.toString().replace(',', '.');
          if (!this.parent.isEdit || this.parent.flag) {
            this.calculateRowPrice();
          }
        },
        immediate: true
      },
      'bd.staffId': function (staffId) {
        this.getEntityList(staffId);
      },
      'bd.rowTotal': function (val) {
        this.bd.rowTotal = val.toString().replace(',', '.');
      },
      'bd.userInputDiscount': function (userInputDiscount) {
        if (userInputDiscount != "%") {
          this.bd.userInputDiscount = userInputDiscount.toString().replace(',', '.');
          if (!this.parent.isEdit || this.parent.flag) {
            this.calculateRowPrice();
          }
        }
      },
      'bd.useCreditPrice': function (val) {
        if (val == true) {
          this.bd.rowTotal = this.bd.entity.basePrice.toString().replace(',', '.');
        }
        else {
          this.bd.rowTotal = this.bd.entity.basePriceWithCard.toString().replace(',', '.');
        }
        if (!this.parent.isEdit || this.parent.flag) {
          this.calculateRowPrice();
        }
      },
      entityKey: async function (key) {
        if (key) {
          let entityType = key.split(',')[0];
          let entityId = key.split(',')[1];

          let entityFromData = this.entityList.find(s => s.id == entityId);
          if (entityFromData) {
            this.entityId = entityId;
            this.entityType = entityType;
            this.bd.entity = entityFromData;
            this.bd.entityId = entityFromData.id;
            this.bd.entityType = entityFromData.entityType;
            this.bd.rowTotal = (this.bd.useCreditPrice ? entityFromData.basePriceWithCard : entityFromData.basePrice);
            this.bd.currencyId = entityFromData.currencyId;
            this.entityKey = this.bd.entity.entityType + ',' + this.bd.entity.id;
          }

          if (this.parent.flag)
            this.calculateRowPrice();
        }
      }
    },
    computed: {
      totalEntityCount() {
        return this.entityCount;
      },
      bd: function () {
        return this.initialBd;
      },
      isRowStaffSelected() {
        return (this.bd.staffId && this.bd.staffId != null && this.bd.staffId.length > 1);
      },
      isRowEntitySelected() {
        return (this.bd.entity && this.bd.entity.id != null && this.bd.entity.id.length > 1 && this.entityKey && this.entityKey.length > 1);
      },
      rowTotalDisabled() {
        if (this.parent.isEdit)
          return false;
        else if (this.$store.getters['Auth/checkPermission']('bill_changeentityprice'))
          return false;
        else
          return true;
      },
      canApplyDiscount() {
        if (this.$store.getters['Auth/checkPermission']('bill_canapplydiscount'))
          return false;
        else
          return true;
      },
      parent() {
        return this.$parent.$parent.$parent;
      }
    },
    mounted: function () {
      this.getStaffList();

      this.getEntityList(this.bd.staffId);

      this.entityKey = this.bd.entity ? this.bd.entity.entityType + ',' + this.bd.entity.id : null;
    },
  }
</script>

<style>
  .iq-card-body-billdetail {
    padding: 0px 15px !important;
  }
</style>

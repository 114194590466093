import axios from './index'

export default {
  getServiceList(serviceId, staffId, staffBranchCalendarId) {

    let url = '/entity/listJson?entityTypes=service&servicesStaffId=' + staffId;

    if (serviceId && serviceId.length > 0) {
      url += `&currentEntityType=service&currentEntityId=${serviceId}`;
    }

    if (staffBranchCalendarId) {
      url += `&staffBranchCalendarId=${staffBranchCalendarId}`;
    }

    return axios.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getEntityList(url) {
    return axios.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  }
}

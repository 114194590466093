import axios from './index'

export default {
  submitAppointmentForm(url, model) {
    return axios.post(url, model)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getAppointment(appointmentId) {
    return axios.get(`/appointment/dataSingle?id=${appointmentId}`)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getAppointmentsForTreatmentSketch(customerId) {
    return axios.get(`/appointment/getAppointmentsForTreatmentSketch?customerId=${customerId}`)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCustomerAppointments(id, customerId) {
    let url = `/appointment/getAppointmentsForCustomerSelection?customerId=${customerId}`;

    if (id && id.length > 0)
      url = url + `&appointmentId=${id}`

    return axios.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getRoomAvailability(params) {
    return axios.get('/appointment/roomAvailability', { params })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getRoomAvailabilityForManualSelected(params) {
    return axios.get('/appointment/isConflicted', { params })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getServiceMinute(serviceId) {
    return axios.get(`/appointment/getServiceLengthMinute?serviceId=${serviceId}`)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  changeStatus(appointmentId, statusId) {
    return axios.post(`/appointment/changeAppointmentStatus?appointmentId=${appointmentId}&statusId=${statusId}`)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getAppointmentBoxData(url) {
    return axios.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  }
}

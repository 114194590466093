import axios from './index'

export default {
  getMultimedia(customerId, multimediaType) {
    return axios.get('/Multimedia/GetCustomerMultimedias?customerId=' + customerId + '&multimediaType=' + multimediaType)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getMultimediaType() {
    return axios.get('/data/shared/MultimediaType')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getTenantCloudStorageStatus() {
    return axios.get('/multimedia/getTenantCloudStorageStatus')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCustomerSketchTemplates() {
    return axios.get('/customerSketch/getCustomerSketchTemplates')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCustomerProfilePicture(customerId) {
    return axios.get('/Multimedia/GetCustomerProfilePicture?customerId=' + customerId)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getBase64ForMultimediaId(multimediaId) {
    return axios.get('/Multimedia/GetBase64ForMultimediaId?multimediaId=' + multimediaId)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateMultimediaDetails(notes, date, groupId, multimediaType) {
    return axios.post("/Multimedia/UpdateMultimediaDetails", { notes: notes, date: date, groupId: groupId, multimediaType: multimediaType })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  uploadProfilePicture(formData) {
    return axios.post('/Multimedia/UploadProfilePicture', formData)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteMultimedia(id) {
    return axios.delete('/Multimedia/DeleteMultimedia?id=' + id)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  generateThumbnailImage(multimediaId) {
    return axios.put('/Multimedia/generateThumbnailImage?multimediaId=' + multimediaId)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  uploadStaffProfilePicture(formData) {
    return axios.post('/Multimedia/UploadStaffProfilePicture', formData)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  }
}

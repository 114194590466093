<template>
  <b-row>
    <b-col md="12">
      <b-button variant="success" size="sm" class="payment-type-button"
                @click="addPaymentItem({ paymentType: 'cash', rowTotal: 0, currencyId: mainCurrencyId , bankInfoTypeId: 6, paymentMethodId:null ,depositCount: 0 })">
        <font-awesome-icon icon="fa-regular fa-plus" /> {{ $t("Cash") }}
      </b-button>
      &nbsp;
      <b-button variant="success" size="sm" class="payment-type-button"
                @click="addPaymentItem({ paymentType: 'creditcard', rowTotal: 0, currencyId: mainCurrencyId, bankInfoTypeId: 1, paymentMethodId:null , depositCount: 0 })">
        <font-awesome-icon icon="fa-regular fa-plus" /> {{ $t("CreditCard") }}
      </b-button>
      &nbsp;
      <b-button variant="success" size="sm" class="payment-type-button"
                @click="addPaymentItem({ paymentType: 'banktransfer', rowTotal: 0, currencyId: mainCurrencyId, bankInfoTypeId: 2, paymentMethodId:null , depositCount: 0 })">
        <font-awesome-icon icon="fa-regular fa-plus" /> {{ $t("BankTransfer") }}
      </b-button>
      &nbsp;
      <b-button variant="success" size="sm" class="payment-type-button"
                @click="addPaymentItem({ paymentType: 'nationalinsurance', rowTotal: 0, currencyId: mainCurrencyId, bankInfoTypeId: 9, paymentMethodId:null , depositCount: 0 })">
        <font-awesome-icon icon="fa-regular fa-plus" /> {{ $t("NationalInsurance") }}
      </b-button>
      &nbsp;
      <b-button variant="success" size="sm" class="payment-type-button"
                @click="addPaymentItem({ paymentType: 'privateinsurance', rowTotal: 0, currencyId: mainCurrencyId, bankInfoTypeId: 4, paymentMethodId:null , depositCount: 0 })">
        <font-awesome-icon icon="fa-regular fa-plus" /> {{ $t("PrivateInsurance") }}
      </b-button>
      &nbsp;
      <b-button variant="success" size="sm" class="payment-type-button"
                @click="addPaymentItem({ paymentType: 'other', rowTotal: 0, currencyId: mainCurrencyId, bankInfoTypeId: 5, paymentMethodId:null , depositCount: 0 })">
        <font-awesome-icon icon="fa-regular fa-plus" /> {{ $t("Other") }}
      </b-button>
      &nbsp;
      <b-button v-if="isEdit" variant="success" size="sm" class="payment-type-button"
                @click="addInstallmentRow()">
        <font-awesome-icon icon="fa-regular fa-plus" /> {{ $t("OpenAccount") }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: 'PaymentType',
    props: {
      isEdit: Boolean
    },
    methods: {
      addPaymentItem(item) {
        this.$emit('addPaymentItem', item);
      },
      addInstallmentRow() {
        this.$emit('addInstallmentRow');
      }
    },
    computed: {
      mainCurrencyId: function () {
        return this.$store.getters['Auth/tenantInfo'].mainCurrencyId;
      }
    }
  }
</script>

<style>
  .payment-type-button {
    font-size: 12px !important;
    padding: 0.2rem 0.4rem !important;
  }
</style>

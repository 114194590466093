<template>
  <b-row v-if="isDeposit && parent.isEdit && depositCount > 0"
         style="margin-top:5px !important; padding:0px 25px;">
    <b-col md="2">
      <span class="font-bold">{{ paymentIndex+1 }}.{{ $t("Instalment") }}</span>
    </b-col>
    <b-col md="4">
      <b-form-input v-model="payment.rowTotal" type="text" @keypress="$root.isNumber"></b-form-input>
    </b-col>
    <b-col md="2">
      <v-select transition="" v-model="payment.currencyId"
                :clearable="false"
                :reduce="s => s.currencyId" label="symbol"
                :options="parent.currencyList">
      <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
    </b-col>
    <b-col md="4">
      <date-picker v-model="payment.payDate" :tag-name="'payDate'" :is-required="true" />
    </b-col>
  </b-row>
  <div v-else style="padding: 3px !important; border-bottom: 1px solid #e2e5ec;">
    <b-row>
      <b-col md="2">
        <span v-if="payment.paymentType == 'cash'"
              class="font-bold">{{ $t("Cash") }}</span>
        <span v-if="payment.paymentType == 'creditcard'"
              class="font-bold">{{ $t("CreditCard") }}</span>
        <span v-if="payment.paymentType == 'banktransfer'"
              class="font-bold">{{ $t("BankTransfer") }}</span>
        <span v-if="payment.paymentType == 'nationalinsurance'"
              class="font-bold">{{ $t("NationalInsurance") }}</span>
        <span v-if="payment.paymentType == 'privateinsurance'"
              class="font-bold">{{ $t("PrivateInsurance") }}</span>
        <span v-if="payment.paymentType == 'other'"
              class="font-bold">{{ $t("Other") }}</span>
        <span v-if="payment.paymentType == 'point'"
              class="font-bold">{{ $t("Point") }}</span>
        <span v-if="payment.paymentType == 'deposit'"
              class="font-bold">{{ $t("OpenAccount") }}</span>

        <input type="hidden" v-model="paymentItem.isDepositPayment" />
      </b-col>

      <b-col md="2">
        <b-form-input v-model="payment.rowTotal" type="text" @keypress="$root.isNumber"></b-form-input>
      </b-col>

      <b-col md="2">
        <v-select transition="" v-model="payment.currencyId"
                  :clearable="false"
                  :reduce="s => s.currencyId" label="symbol"
                  :options="parent.currencyList">
        <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
      </b-col>

      <b-col md="3" v-if="parent.isEdit">
        <date-picker v-model="payment.payDate" :tag-name="'payDate'" :is-required="(payment.rowTotal > 0 && !payment.payDate ? false : true)" />
      </b-col>

      <b-col md="3" v-if="isDeposit && !payment.payDate">
        <ValidationProvider :rules="(depositCountRequired ? 'required' :'')">
          <b-form-input v-model="depositCount" type="text" :class="(depositCountRequired ? ' is-invalid' : '')"
                        @keypress="$root.isNumber"></b-form-input>
          <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
        </ValidationProvider>
      </b-col>

      <b-col md="3" v-if="showBankInfoSection && !isDeposit">
        <ValidationProvider :name="$t('Staff')" :rules="(bankInfoRequired ? 'required' :'')">
          <v-select transition="" v-model="payment.bankInfoId"
                    :reduce="s => s.id" label="name"
                    :options="bankInfoList"
                    :class="(bankInfoRequired ? 'is-invalid' : '')"
                    :required="(bankInfoRequired ? 'required' :'')">
          <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
          <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
        </ValidationProvider>
      </b-col>
    </b-row>

    <iq-card v-if="isDeposit && depositCount > 0 && payment.rowTotal > 0">
      <template v-slot:headerTitle>
        <h5>{{ $t("Instalments") }}</h5>
      </template>
      <template v-slot:body>
        <b-row v-for="(deposit,index) in depositCountArray" :key="index" class="mb-2">
          <b-col md="2">
            <span class="font-bold">{{ index+1 }}.{{ $t("Instalment") }}</span>
          </b-col>
          <b-col md="4">
            <b-form-input v-model="deposit.rowTotal" type="text" @keypress="$root.isNumber"></b-form-input>
          </b-col>
          <b-col md="4">
            <date-picker v-model="deposit.payDate" :tag-name="'payDate'" :is-required="true" />
          </b-col>
        </b-row>
      </template>
    </iq-card>

  </div>
</template>

<script>
  import billService from '../../services/bill'
  import DatePicker from '../shared/DatePicker'

  export default {
    name: 'BillPaymentRow',
    components: {
      DatePicker
    },
    props: {
      paymentItem: { rowTotal: 0, currencyId: 0, paymentType: '', bankInfoTypeId: null, paymentMethodId: null }, paymentIndex: Number
    },
    data() {
      return {
        bankInfoList: [],
        paymentMethodList: [],
        currencyList: [],
        depositCount: 1
      }
    },
    methods: {
      getBankInfoList() {

        let bankInfoTypeId = 0;

        if (this.payment.paymentType == 'creditcard')
          bankInfoTypeId = 1;
        else if (this.payment.paymentType == 'banktransfer')
          bankInfoTypeId = 2;
        else if (this.payment.paymentType == 'nationalinsurance')
          bankInfoTypeId = 9;
        else if (this.payment.paymentType == 'privateinsurance')
          bankInfoTypeId = 4;
        else if (this.payment.paymentType == 'other')
          bankInfoTypeId = 5;

        let bankInfoId = null;
        if (this.payment && this.payment.bankInfoId)
          bankInfoId = this.payment.bankInfoId;

        billService.getBankInfoList(bankInfoTypeId, bankInfoId).then(response => { this.bankInfoList = response; });
      },
      getDepositDate: function (monthIndex) {
        return this.$moment().add((monthIndex + 1), 'M').subtract(0, 'day').format("DD.MM.YYYY");
      },
      getCurrencyList() {
        this.currencyList = this.parent.currencyList;
      }
    },
    watch: {
      'payment.rowTotal': function () {
        this.payment.rowTotal = this.payment.rowTotal.toString().replace(',', '.');

        if (!this.parent.isEdit) {
          if (this.isDeposit && this.payment.rowTotal != 0) {
            this.depositCount = 1;
          }
          else if (this.isDeposit) { this.depositCount = 0; }
        }
      },
      'depositCount': function () {
        if (this.depositCount > 20)
          this.depositCount = 20;

        if (this.payment.rowTotal && this.payment.rowTotal > 0 && parseInt(this.depositCount) === 0)
          this.depositCount = 1;
      },
      'depositCountArray': function (val) {
        if (!this.parent.isEdit && this.payment.paymentType == 'deposit') {
          this.parent.model.accountingDebt = val;
        }
      }
    },
    computed: {
      payment() {
        return this.paymentItem;
      },
      parent() { return this.$parent.$parent.$parent; },
      showBankInfoSection() {
        return (this.payment.paymentType == 'creditcard' || this.payment.paymentType == 'banktransfer' || this.payment.paymentType == 'nationalinsurance' || this.payment.paymentType == 'privateinsurance' || this.payment.paymentType == 'other')
      },
      bankInfoRequired() {
        return (this.payment.paymentType == 'creditcard' || this.payment.paymentType == 'banktransfer'
          || this.payment.paymentType == 'other' || this.payment.paymentType == 'nationalinsurance'
          || this.payment.paymentType == 'privateinsurance')
          && this.payment.rowTotal > 0 && !this.payment.bankInfoId;
      },
      isDeposit() { return this.payment.paymentType == 'deposit'; },
      totalDepositCount() {
        return this.parent.model.accountingDebt ? this.parent.model.accountingDebt.length : 1;
      },
      depositCountRequired() {
        return this.payment.rowTotal > 0 && this.isDeposit && !this.depositCount;
      },
      depositCountArray: function () {
        let $this = this;
        var ar = new Array();
        if (this.payment.paymentType == 'deposit') {
          for (var i = 0; i < this.depositCount; i++) {
            ar.push({ rowTotal: ($this.payment.rowTotal / $this.depositCount), currencyId: $this.payment.currencyId, payDate: $this.getDepositDate(i), paymentType: $this.payment.paymentType })
          }
          return ar;
        }
        else
          return ar;
      }
    },
    mounted: function () {
      this.depositCount = this.totalDepositCount;

      if (this.payment.paymentType == 'creditcard'
        || this.payment.paymentType == 'banktransfer' || this.payment.paymentType == 'nationalinsurance'
        || this.payment.paymentType == 'privateinsurance' || this.payment.paymentType == 'other') {
        this.getBankInfoList();
      }
    }
  }
</script>

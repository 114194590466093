import axios from './index'

export default {
  submitBillForm(url, model) {
    return axios.post(url, model)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getBill(billId) {
    return axios.get(`/bill/dataSingle?id=${billId}`)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getBankInfoList(bankInfoTypeId, bankInfoId) {
    return axios.get('/data/accounting/bankInfo', bankInfoTypeId != 0 ? { params: { bankInfoTypeId: bankInfoTypeId, id: bankInfoId } } : {})
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCustomerBills(customerId) {
    let url = '/bill/getBillsForCustomerSelection?customerId=' + customerId;

    return axios.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getTotalData(url) {
    return axios.get(url)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createFromAppointment(appointmentId) {
    return axios.get(`/appointment/getAppointmentServicesAsBillDetailsJson?appointmentId=${appointmentId}`)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  }
}

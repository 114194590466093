<template>
  <b-container fluid>
    <ValidationObserver ref="billFormObserver" v-slot="{ invalid }">
      <form>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ isEdit ? $t("BillEdit") : $t("NewBill") }}</h4>
          </template>
          <template v-slot:body>
            <customer-selection :component-id="componentId" :component-name="componentName"></customer-selection>

            <hr />
            <section v-if="isCustomerSelected">

              <b-row>
                <b-form-group class="col-md-4" v-show="!isEdit || (isEdit && !canCreateWithPastOrFutureDate)">
                  <date-picker :disabled="canCreateWithPastOrFutureDate" v-model="model.bill.date" :tag-name="'billDate'" :is-required="true" />
                  <span class="text-danger error-mes"
                        v-if="hasAnySalesRow && !model.bill.date"
                        v-text="$t('ValidationMessage.CannotBeEmpty')" />
                </b-form-group>
                <b-form-group class="col-md-4" v-show="!isEdit || (isEdit && !canCreateWithPastOrFutureDate)">
                  <time-picker v-model="model.bill.time" :disabled="canCreateWithPastOrFutureDate" :is-required="true"></time-picker>
                </b-form-group>
                <b-form-group class="col-md-4" v-show="!isEdit || (isEdit && !canCreateWithPastOrFutureDate)">
                  <input type="text" v-model="model.bill.trackingNumber" :placeholder="$t('TrackingNumber')" class="form-control">
                </b-form-group>
              </b-row>

              <div v-if="isEdit">
                <bill-detail v-for="(bd, bdIndex) in model.details" :key="bd.id" :initial-bd="bd" :bd-index="bdIndex"
                             @removeEntity="removeEntityRow" :entity-count="entityCount" />
              </div>
              <div v-else>
                <bill-detail v-for="(bd, bdIndex) in model.details" :key="'bd_'+bdIndex+'_'+bd.entityId" :initial-bd="bd" :bd-index="bdIndex"
                             @removeEntity="removeEntityRow" :entity-count="entityCount" />
              </div>

              <b-row>
                <b-col md="12" class="text-right mt-3">
                  <b-button variant="light" @click="addEntityRow">
                    <font-awesome-icon icon="fa-regular fa-plus" /> {{ $t("AddNewLine") }}
                  </b-button>
                </b-col>
              </b-row>

              <hr />

              <payment-type :is-edit="isEdit" @addPaymentItem="addPaymentItem" @addInstallmentRow="addInstallmentRow" />

              <br />

              <b-row>
                <b-col md="9" v-if="model.accounting && model.accounting.length > 0">
                  <bill-payment-row v-for="(pr,prIndex) in model.accounting" :key="'pr_'+prIndex" :payment-item="pr" :payment-index="prIndex" />
                </b-col>
                <b-col md="9" v-if="isEdit && model.accountingDebt && model.accountingDebt.length > 0">
                  <bill-payment-row v-for="(pr,prIndex) in model.accountingDebt" :key="'pr_'+prIndex" :payment-item="pr" :payment-index="prIndex" />
                </b-col>

                <b-col md="3">
                  <b-col md="12">
                    <b-alert :show="true" variant="info">
                      <div class="iq-alert-text"> {{ $t("Total") }}: {{ globalSaleTotal }} {{ mainCurrencySymbol }} </div>
                    </b-alert>
                  </b-col>
                  <b-col md="12">
                    <b-alert :show="true" variant="success">
                      <div class="iq-alert-text"> {{ $t("Apportioned") }}: {{ globalPaidTotal }} {{ mainCurrencySymbol }} </div>
                    </b-alert>
                  </b-col>
                  <b-col md="12">
                    <b-alert :show="true" variant="danger">
                      <div class="iq-alert-text"> {{ $t("Balance") }}: {{ globalOpenTotal }} {{ mainCurrencySymbol }} </div>
                    </b-alert>
                  </b-col>
                </b-col>
              </b-row>

              <b-row>
                <b-form-group class="col-md-12" :label="$t('Notes')" label-for="notes">
                  <b-form-textarea v-model="model.bill.notes" rows="3"></b-form-textarea>
                </b-form-group>
              </b-row>

            </section>

          </template>
        </iq-card>

        <modal-footer-button v-if="isCustomerSelected && isMoneyDistributionCorrect"
                             :set-is-submitting="isSubmitting"
                             @submit="submitNewBill"></modal-footer-button>
        <div v-else-if="isCustomerSelected" class="modal-footer">
          <b-button class="btn btn-danger">{{ $t("CheckRevenue") }}</b-button>
        </div>

      </form>
    </ValidationObserver>
  </b-container>
</template>

<script>
  import commonService from '../../../services/common'
  import billService from '../../../services/bill'

  import { getToday, getNow } from '../../../utils/dateTimeExtensions'

  import CustomerSelection from '../../../components/customer/CustomerSelection'
  import BillDetail from '../../../components/bill/BillDetail'
  import PaymentType from '../../../components/bill/PaymentType'
  import BillPaymentRow from '../../../components/bill/BillPaymentRow'
  import DatePicker from '../../../components/shared/DatePicker'
  import TimePicker from '../../../components/shared/TimePicker'
  import ModalFooterButton from '../../../components/shared/ModalFooterButton'

  export default {
    name: 'NewBill',
    props: {
      billId: String,
      customerId: String,
      appointmentId: String
    },
    components: {
      CustomerSelection,
      BillDetail,
      PaymentType,
      BillPaymentRow,
      DatePicker,
      TimePicker,
      ModalFooterButton
    },
    data() {
      return {
        model: {
          bill: { date: getToday(), time: getNow() },
          details: [{
            staffId: '',
            helperStaffList: [{ helperStaffId: null, helperStaffRoleId: null, isDeleted: false }],
            proposeDetailId: null,
            appointmentServiceId: null,
            entityType: '',
            entityId: '',
            quantity: 1,
            discount: 0,
            discountRate: 0,
            userInputDiscount: '',
            useCreditPrice: false,
            isDeleted: false,
            currencyId: this.mainCurrencyId,
            rowTotal: 0,
            entity: {
              entityType: '', id: '', name: '', entityCategoryName: '', basePrice: 0, basePriceWithCard: 0, currencyId: this.mainCurrencyId
            }
          }],
          accounting: [],
          accountingDebt: [],
          deletedBillDetails: []
        },
        currencyList: [{ id: '', symbol: '' }],
        parityList: [],
        selectedCustomerId: '',
        entityCount: 1,
        deletedBillDetails: '',
        totals: [{}],
        globalSaleTotal: 0,
        globalPaidTotal: 0,
        globalDebtTotal: 0,
        globalOpenTotal: 0,
        currencyGroup: [],
        isMoneyDistributionCorrect: false,
        flag: false, // Bu flag ödeme düzenleme ve tekliften ödeme oluşturmada ilk sayfaya gidildiğinde calculateRowPrice ın çalışmasını engelliyor.,
        isSubmitting: false
      };
    },
    methods: {
      async submitNewBill() {
        const isValid = await this.$refs.billFormObserver.validate();
        const isValidAcc = this.isFormValid();
        if (!isValid || !isValidAcc) {
          this.$toastr.error(this.$t('ValidationMessage.EmptyAndValidateError'));
          return;
        }

        let hasPermission = false;

        if (this.isEdit && this.$store.getters['Auth/checkPermission']('bill_edit')) {
          hasPermission = true;
        }
        else if (this.isEdit && (this.$store.getters['Auth/checkPermission']('bill_editonlycreated') && this.$moment(this.model.bill.date, "DD.MM.YYYY HH:mm").format("DD.MM.YYYY") == window.getToday())) {
          hasPermission = true;
        }

        if (this.isEdit && !hasPermission) {
          this.$toastr.error(this.$t("PermissionNotGranted"));
          return false;
        }

        if (this.model.details && this.model.details.length > 0) {
          this.model.details.forEach(z => {
            if (!z.entityId && z.entityId.length == 0)
              z.entity = null;
          });
        }

        if (this.model.accounting && this.model.accounting.length > 0) {
          this.model.accounting.forEach(z => {
            if (!z.rowTotal || z.rowTotal.length == 0)
              z.rowTotal = 0;
          });
        }

        if (this.model.accountingDebt && this.model.accountingDebt.length > 0) {
          this.model.accountingDebt.forEach(z => {
            if (!z.rowTotal || z.rowTotal.length == 0)
              z.rowTotal = 0;
          });
        }

        this.isSubmitting = true;
        let url = "/bill/new";
        if (this.billId && this.billId.length > 0)
          url = "/bill/edit"

        billService.submitBillForm(url, this.model)
          .then(response => {
            this.isSubmitting = false;
            if (response) {
              this.$toastr.success(this.$t("Success"));
              this.$router.push({ name: 'customerSummary.bills', params: { customerId: this.model.bill.customerId } });
            }
            else {
              this.$toastr.error(this.$t("Error"));
            }
          }).finally(() => { this.isSubmitting = false; });
      },
      getBill() {
        if (this.billId && this.billId.length > 0) {
          billService.getBill(this.billId).then(response => {
            if (response) {
              this.model = response;
              this.$bus.$emit('CustomerSelectionComponent_onSelected', this.model.bill.customerId);
              this.selectedCustomerId = this.model.bill.customerId;
              this.entityCount = this.model.details.length;
            }
          }).finally(() => {
            this.flag = true;
          });
        }
      },
      addEntityRow() {
        this.entityCount++;
        let staffId = '';

        if (this.model.details[0]?.staffId)
          staffId = this.model.details[0]?.staffId;

        this.model.details.push(
          {
            staffId: staffId,
            currencyId: this.mainCurrencyId,
            helperStaffList: [{ helperStaffId: null, helperStaffRoleId: null, isDeleted: false }],
            useCreditPrice: false,
            quantity: 1,
            userInputDiscount: 0,
            rowTotal: 0,
            entityId: '',
            entityType: '',
            entity: {
              basePrice: 0,
              basePriceWithCard: 0,
              currencyId: this.mainCurrencyId,
              entityCategoryName: '',
              id: '',
              name: '',
              entityType: '',
            }
          });
      },
      removeEntityRow(bd) {
        this.entityCount--;
        if (this.billId != null && this.billId.length > 0 && bd.id && bd.id.length > 0) {
          this.model.deletedBillDetails.push(bd.id);
        }
        this.model.details = this.model.details.filter(el => el != bd)
        this.calculateTotal();
      },
      addInstallmentRow() {
        var refDebtRow = this.model.accountingDebt[this.model.accountingDebt.length - 1];
        this.model.accountingDebt.push(
          {
            currencyId: refDebtRow ? refDebtRow.currencyId : this.mainCurrencyId,
            isDepositRow: false,
            payDate: refDebtRow ? refDebtRow.payDate : getToday(),
            paymentType: refDebtRow ? refDebtRow.paymentType : 'deposit',
            rowTotal: 0
          }
        );
      },
      addPaymentItem({ rowTotal, currencyId, paymentType, bankInfoTypeId, paymentMethodId, depositCount }) {
        if (!this.isEdit)
          this.model.accounting.push({ rowTotal, currencyId, paymentType, bankInfoTypeId, paymentMethodId, depositCount });
        else
          this.model.accounting.push({ rowTotal, currencyId, paymentType, bankInfoTypeId, paymentMethodId, depositCount, payDate: getToday() });

      },
      getCurrencyList() {
        commonService.getCurrencyList().then(response => {
          this.currencyList = response;
          this.getParities();
        });
      },
      getParities() {
        commonService.getParities().then(response => {
          this.parityList = response;
        }).finally(() => {
          if (this.appointmentId)
            this.createFromAppointment();
          else
            this.getBill();
        });
      },
      currencyExchange(rowTotal, toCurrency, fromCurrency) {
        let foundParity = this.parityList.find(el => el.fromCurrencyId == fromCurrency && el.toCurrencyId == toCurrency);
        if (!foundParity)
          return (rowTotal * 1);
        else
          return (rowTotal * foundParity.parity);

      },
      currencyExchangeReverse(rowTotal, toCurrency, fromCurrency) {
        let foundParity = this.parityList.find(el => el.fromCurrencyId == fromCurrency && el.toCurrencyId == toCurrency);
        if (!foundParity)
          return (rowTotal * 1);
        else
          return (rowTotal / foundParity.parity);

      },
      calculateTotal() {
        let saleTotal = 0;
        //model.details foreach, saleTotal
        for (let i = 0; i < this.salesTotals.length; i++) {
          let saleRow = this.salesTotals[i];
          saleTotal += this.currencyExchange(saleRow.price, saleRow.currencyId, this.mainCurrencyId);

          //20 tl + 10 eur yazmasını istemiyopruz toplamda, 90 tl yazmasını istiyoruz.
        }
        saleTotal = parseFloat(saleTotal.toFixed(4));


        let paidTotal = 0;
        for (let i = 0; i < this.paymentTotals.length; i++) {
          let paymentRow = this.paymentTotals[i];
          paidTotal += this.currencyExchange(paymentRow.price, paymentRow.currencyId, this.mainCurrencyId);

        }
        paidTotal = parseFloat(paidTotal.toFixed(4));


        let debtTotal = 0;
        for (let i = 0; i < this.debtTotals.length; i++) {
          let debtRow = this.debtTotals[i];
          debtTotal += this.currencyExchange(debtRow.price, debtRow.currencyId, this.mainCurrencyId);

        }
        debtTotal = parseFloat(debtTotal.toFixed(4));


        let depositTotal = 0;
        for (let i = 0; i < this.depositTotal.length; i++) {
          let depositRow = this.depositTotal[i];
          depositTotal += this.currencyExchange(depositRow.price, depositRow.currencyId, this.mainCurrencyId);

        }
        depositTotal = parseFloat(depositTotal.toFixed(4));


        if (depositTotal > debtTotal) {
          paidTotal = paidTotal - (depositTotal - debtTotal);
        }
        else if (depositTotal < debtTotal) {
          paidTotal = paidTotal + (debtTotal - depositTotal);
        }

        this.globalSaleTotal = saleTotal.toFixed(2);
        this.globalPaidTotal = paidTotal.toFixed(2);
        this.globalDebtTotal = debtTotal.toFixed(2);
        this.globalOpenTotal = ((paidTotal - saleTotal) * (-1)).toFixed(2);


        if (this.globalOpenTotal == 0.00 || this.globalOpenTotal == -0.01) {
          this.isMoneyDistributionCorrect = true;
        }
        else {
          this.isMoneyDistributionCorrect = false;

          this.currencyGroup = [];

          if (this.grouppedByCurrencyId(this.salesTotals, 'currencyId')?.length > 1)
            this.isMoneyDistributionCorrect = true;

          if (this.grouppedByCurrencyId(this.paymentTotals, 'currencyId')?.length > 1)
            this.isMoneyDistributionCorrect = true;

          if (this.grouppedByCurrencyId(this.debtTotals, 'currencyId')?.length > 1)
            this.isMoneyDistributionCorrect = true;

          if (this.grouppedByCurrencyId(this.depositTotal, 'currencyId')?.length > 1)
            this.isMoneyDistributionCorrect = true;

          //if (this.isMoneyDistributionCorrect && this.globalOpenTotal <= this.billDistributionThreshold && this.globalOpenTotal >= (this.billDistributionThreshold * -1))
          //  this.isMoneyDistributionCorrect = true;
          else
            this.isMoneyDistributionCorrect = false;

        }
      },
      setCustomer() {
        this.$bus.$emit('CustomerSelectionComponent_onSelected', this.customerId);
      },
      createFromAppointment() {
        if (this.appointmentId && this.appointmentId.length > 0) {
          billService.createFromAppointment(this.appointmentId)
            .then(response => {
              if (response) {
                this.entityCount = response.length;
                for (var i = 0; i < response.length; i++) {
                  let appointmentService = response[i];
                  this.model.details.push(
                    {
                      appointmentServiceId: appointmentService.appointmentServiceId,
                      staffId: appointmentService.staffId,
                      helperStaffList: [{ helperStaffId: null, helperStaffRoleId: null, isDeleted: false }],
                      currencyId: appointmentService.currencyId,
                      useCreditPrice: appointmentService.useCreditPrice,
                      quantity: appointmentService.quantity,
                      entityId: appointmentService.entityId,
                      entityType: appointmentService.entityType,
                      userInputDiscount: 0,
                      rowTotal: (appointmentService.useCreditPrice ? appointmentService.entity.basePriceWithCard : appointmentService.entity.basePrice),
                      entity: {
                        basePrice: appointmentService.entity.basePrice,
                        basePriceWithCard: appointmentService.entity.basePriceWithCard,
                        currencyId: appointmentService.entity.currencyId,
                        entityCategoryName: appointmentService.entity.entityCategoryName,
                        id: appointmentService.entity.id,
                        name: appointmentService.entity.name,
                        entityType: appointmentService.entity.entityType
                      }
                    });
                }
              }
            });
        }
      },
      grouppedByCurrencyId: function (array, key) {

        let $this = this;

        var s = array.reduce(function (rv, x) {
          if (x.price > 0) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
          }
          return rv;
        }, {});

        let keys = Object.keys(s);

        if (keys) {
          keys.map(function (v) {
            if (!$this.currencyGroup.some(z => z == v))
              $this.currencyGroup.push(v);
          })
        }
        return $this.currencyGroup;
      },
      otherCurrencyResult(currencyId, amount) {
        let mainCurrency = this.mainCurrencyId;
        if (currencyId && currencyId.length > 0 && currencyId != mainCurrency) {

          let total = this.currencyExchangeReverse(amount, currencyId, mainCurrency);
          let symbol = this.currencyList?.find(z => z.currencyId == currencyId)?.symbol;

          if (symbol && total)
            return `${total.toFixed(2)} ${symbol}`;
        }
      },
      isFormValid() {
        let result = this.model.accounting.filter(z =>
          (z.paymentType == 'creditcard' && z.rowTotal > 0 && !z.bankInfoId)
          || z.paymentType == 'banktransfer' && z.rowTotal > 0 && !z.bankInfoId
          || z.paymentType == 'nationalinsurance' && z.rowTotal > 0 && !z.bankInfoId
          || z.paymentType == 'privateinsurance' && z.rowTotal > 0 && !z.bankInfoId
          || z.paymentType == 'other' && z.rowTotal > 0 && !z.bankInfoId
          || (z.paymentType == 'deposit' && z.rowTotal > 0 && (!this.model.accountingDebt || this.model.accountingDebt.length == 0))
          || (this.isEdit && !z.payDate));
        if (result.length == 0 && this.hasAnySalesRow && this.model.bill.date) return true;
        else return false;
      }
    },
    watch: {
      "model.details": {
        handler: function () {
          this.calculateTotal();
        },
        deep: true
      },
      "model.accounting": {
        handler: function () {
          this.calculateTotal();
        },
        deep: true
      },
      "model.accountingDebt": {
        handler: function () {
          this.calculateTotal();
        },
        deep: true
      },
      "model.bill.time": function (val) {
        if (val && val.length > 0)
          this.model.bill.time = this.model.bill.time.replace('.', ':');
      }
    },
    computed: {
      isEdit: function () { return this.billId && this.billId.length > 0 },
      componentId: function () {
        if (this.appointmentId && this.appointmentId.length > 0)
          return this.appointmentId;
        else
          return this.billId;
      },
      componentName: function () {
        if (this.appointmentId && this.appointmentId.length > 0)
          return 'appointment';
        else
          return 'bill';
      },
      //allowSellOutOfStockProduct: function () { return this.$store.getters.tenantInfo.allowSellOutOfStockProduct },
      //billDistributionThreshold: function () { return parseFloat(this.$store.state.billDistributionThreshold) },
      isCustomerSelected: function () {
        return this.selectedCustomerId != null && this.selectedCustomerId.length > 0;
      },
      salesTotals: function () {
        return this.model?.details.filter(el => !isNaN(el.rowTotal)).map(function (v) {
          return { price: v.rowTotal, currencyId: v.currencyId }
        });
      },
      paymentTotals: function () {
        return this.model?.accounting.filter(el => !isNaN(el.rowTotal)).map(function (v) {
          return { price: v.rowTotal, currencyId: v.currencyId }
        });
      },
      debtTotals: function () {
        return this.model?.accountingDebt.filter(el => !isNaN(el.rowTotal)).map(function (v) {
          return { price: v.rowTotal, currencyId: v.currencyId }
        });
      },
      depositTotal: function () {
        return this.model?.accounting.filter(el => !isNaN(el.rowTotal) && el.paymentType == 'deposit').map(function (v) {
          return { price: v.rowTotal, currencyId: v.currencyId }
        });
      },
      hasAnySalesRow: function () {
        return this.model.details && this.model.details.filter(el => el.entity.id && el.entity.id.length > 0).length > 0;
      },
      hasAccountingDepositRow: function () {
        return this.model.accounting && this.model.accounting.filter(el => el.paymentType == 'deposit').length > 0;
      },
      canCreateWithPastOrFutureDate: function () {
        return this.$store.getters['Auth/checkPermission']('bill_cancreatewithpastorfuturedate') ? false : true;
      },
      mainCurrencyId: function () {
        return this.$store.getters['Auth/tenantInfo'].mainCurrencyId;
      },
      mainCurrencySymbol: function () {
        return this.$store.getters['Auth/tenantInfo'].mainCurrencySymbol;
      }
    },
    created: function () {
      if (!this.selectedCustomerId) {
        this.$bus.$on('CustomerSelectionComponent_onSelected', (value) => {
          this.selectedCustomerId = value;
          this.model.bill.customerId = value;
        });
      }
    },
    destroyed() {
      this.$bus.$off('CustomerSelectionComponent_onSelected');
    },
    mounted() {
      if (!this.billId) {
        this.addPaymentItem({ paymentType: 'cash', rowTotal: 0, currencyId: this.mainCurrencyId, bankInfoTypeId: 6, paymentMethodId: null });
        this.addPaymentItem({ paymentType: 'creditcard', rowTotal: 0, currencyId: this.mainCurrencyId, bankInfoTypeId: 1, paymentMethodId: null });
        this.addPaymentItem({ paymentType: 'deposit', rowTotal: 0, currencyId: this.mainCurrencyId, bankInfoTypeId: null, paymentMethodId: null });
      }

      if (this.appointmentId && this.appointmentId.length > 0) {
        this.model.bill.appointmentId = this.appointmentId;
        this.model.details = [];
        this.setCustomer();
      }

      if (!this.billId && !this.appointmentId && this.customerId) {
        this.setCustomer();
      }

      this.getCurrencyList();
    }
  }
</script>

<template>
  <div>
    <date-picker v-model="selectedTime"
                 :time-picker-options="options"
                 :disabled-time="notBeforeStartOClock"
                 :disabled="disabled"
                 format="HH:mm"
                 valueType="HH:mm"
                 type="time"
                 placeholder="HH:mm"
                 style="padding:0px !important;"
                 inputClass="form-control"
                 @input="selectedValues"
                 :editable="editable"
                 :clearable="true"></date-picker>
    <span class="text-danger error-mes" v-if="!isTimeSelected && isRequired">{{ $t("ValidationMessage.TimeRequired") }}</span>
  </div>
</template>

<script>
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import 'vue2-datepicker/locale/tr';

  export default {
    name: 'TimePicker',
    components: { DatePicker },
    props: {
      value: String,
      isRequired: Boolean,
      startTime: String,
      endTime: String,
      stepTime: String,
      editable: {
        default: true,
        type: Boolean
      },
      disabled: Boolean,
    },
    data() {
      return {
        options: {
          start: this.$router.currentRoute.path == '/settings/generalSettings' ? '06:00' : this.$store.getters['Calendar/calendarStartTime'],
          step: '00:05',
          end: this.$router.currentRoute.path == '/settings/generalSettings' ? '22:00' : this.$store.getters['Calendar/calendarEndTime']
        }
      };
    },
    methods: {
      selectedValues(event) {
        if (this.$router.currentRoute.path == '/settings/generalSettings') {
          this.$emit('input', event);
        }
        else {
          if (event >= this.$store.getters['Calendar/calendarStartTime'] && event <= this.$store.getters['Calendar/calendarEndTime'])
            this.$emit('input', event);
        }

      },
      notBeforeStartOClock(date) {
        if (this.$router.currentRoute.path == '/settings/generalSettings')
          return date.getHours() <= this.$store.getters['Calendar/calendarStartTime'] || date.getHours() >= this.$store.getters['Calendar/calendarEndTime'];
        else
          return date.getHours() <= this.$store.getters['Calendar/calendarStartTime'] || date.getHours() >= this.$store.getters['Calendar/calendarEndTime'];
      }
    },
    computed: {
      isTimeSelected: function () {
        if (this.selectedTime || this.value) { this.$emit("input", this.selectedTime); return true; }
        else { return false }
      },
      selectedTime: {
        get: function () {
          return this.value;
        },
        set: function (newValue) {
          return newValue;
        }
      }
    },
    mounted: function () {
      if (this.startTime && this.startTime.length > 0)
        this.options.start = this.startTime;

      if (this.endTime && this.endTime.length > 0)
        this.options.end = this.endTime;

      if (this.stepTime && this.stepTime.length > 0)
        this.options.step = this.stepTime;
    }
  };
</script>

import axios from './index'

export default {
  getStaffList(staffId) {
    return axios.get("/staff/listJson", staffId)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getAuthGroups() {
    return axios.get('/data/common/authGroup')
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getStaffEmailIsExists(id, email) {
    return axios.get('/staff/checkStaffEmail?staffId=' + id + '&email=' + email)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCurrentStaffBranches() {
    return axios.get('/staff/getCurrentStaffBranches')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getStaff(id) {
    return axios.get('/staff/getStaffForEdit?staffId=' + id)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  submitStaffForm(model, url) {
    return axios.post(url, model)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getStaffProfilePicture(staffId) {
    return axios.get('/Multimedia/GetStaffProfilePicture?staffId=' + staffId)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getStaffProfile() {
    return axios.get('/Staff/StaffProfile')
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateStaffProfileFast(editModel) {
    return axios.patch('/Staff/UpdateStaffProfileFast', editModel)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  noteToggle(note) {
    return axios.patch("/Staff/CustomerNoteToggleCompleted?customerNoteID=" + note.id)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  isLoggedStaff(staffId) {
    return axios.get("/Staff/IsLoggedStaff?staffId=" + staffId)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  submitChangePasswordForm(url, params) {
    return axios.post(url, params)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
}

<template>
  <div v-if="customerId==''">
    <tab-nav :tabs="true" :fill="true">
      <tab-nav-items active id="search-customer-tab" ariaControls="SearchCustomer"
                     role="tab" :ariaSelected="true">
        <template v-slot:title>
          <font-awesome-icon icon="fa-light fa-magnifying-glass" size="lg" /> {{ $t('SearchCustomer') }}
        </template>
      </tab-nav-items>
      <tab-nav-items id="new-customer-tab" ariaControls="NewCustomer"
                     role="tab" :ariaSelected="false">
        <template v-slot:title>
          <font-awesome-icon icon="fa-light fa-user-plus" size="lg" /> {{ $t('NewCustomer') }}
        </template>
      </tab-nav-items>
    </tab-nav>
    <tab-content>
      <tab-content-item active id="SearchCustomer" aria-labelled-by="search-customer-tab">
        <b-row class="d-flex justify-content-center mt-4">
          <b-col md="7" class="align-self-center">
            <auto-complete data-url="/Customer/AutoComplete"
                           data-hidden-name="CustomerSelectionComponent" />
          </b-col>
          <b-col md="1" class="align-self-center">
            <b-button variant="outline-danger">
              <font-awesome-icon icon="fa-regular fa-triangle-exclamation" beat-fade size="lg" />
            </b-button>
          </b-col>
        </b-row>
      </tab-content-item>
      <tab-content-item id="NewCustomer" aria-labelled-by="new-customer-tab">
        <FastNewCustomer />
      </tab-content-item>
    </tab-content>
  </div>
  <iq-card v-else body-class="customer-info-card">
    <template v-slot:body>
      <tab-nav :pills="true" class="mb-3">
        <tab-nav-items v-if="!componentId" :active="true" href="#pills-customerInfo"
                       :title="$t('CustomerInformations')" />
        <tab-nav-items v-else :active="true" href="#pills-customerInfo"
                       :title="(showBodyForCustomerInfo ? $t('CustomerInformations') : (customerInfo == null ? '' : customerInfo.name))" />

        <tab-nav-items :active="false" href="#pills-appointmentInfo"
                       v-if="$store.getters['Auth/checkPermission']('appointment_show') && componentName && componentName == 'appointment' && componentId"
                       :title="$t('AppointmentInfo')" @click="getCustomerAppointments(componentId)" />

        <tab-nav-items :active="false" href="#pills-appointments"
                       v-if="$store.getters['Auth/checkPermission']('appointment_show')"
                       :title="$t('Appointments')" @click="getCustomerAppointments()" />

        <tab-nav-items :active="false" href="#pills-payments"
                       v-if="$store.getters['Auth/checkPermission']('bill_show')"
                       :title="$t('Payments')" @click="getCustomerBills()" />

        <tab-nav-items :active="false" href="#pills-tags"
                       v-if="$store.getters['Auth/checkPermission']('customer_details')"
                       :title="$t('Tags')" @click="getCustomerTags()" />

        <li v-if="componentId" class="nav-item align-self-center ml-1">
          <button type="button"
                  class="btn btn-outline-info btn-sm btn-icon"
                  @click="collapseTagsToggle()">
            <font-awesome-icon :icon="showBodyForCustomerInfo ? 'fa-regular fa-angle-up':'fa-regular fa-angle-down'"></font-awesome-icon>
          </button>
        </li>
        <li v-if="!componentId" class="nav-item align-self-center ml-1">
          <button type="button"
                  class="btn btn-outline-danger btn-sm"
                  @click="closeCustomerInfo()">
            <font-awesome-icon icon="fa-regular fa-xmark" size="lg" />&nbsp;{{ $t('ClearSelection') }}
          </button>
        </li>
      </tab-nav>
      <tab-content v-show="!componentId || showBodyForCustomerInfo">
        <tab-content-item :active="true" id="pills-customerInfo" aria-labelled-by="pills-customerInfo-tab">
          <b-row class="pl-2 pr-2">
            <b-col md="6" class="d-flex">
              <div>
                <img v-if="profilePicture && $store.getters['Auth/checkPermission']('multimedia_show')"
                     id="imageHolder"
                     :src="profilePicture.thumbnailUrl"
                     style="height:100px; width:100px;"
                     alt="image" />
                <img v-else
                     src="https://s3.stellamedi.com/stellamedi-cdn/images/default-user-image.jpg"
                     id="imageHolder"
                     style="height: 100px; width: 100px;"
                     alt="image" />
              </div>
              <div class="align-self-center ml-3">
                <div v-if="$store.getters['Auth/checkPermission']('customer_details')">
                  <router-link :to="{name: 'customerSummary.details', params: { customerId: customerId }}"
                               style="text-decoration: underline; cursor: pointer; font-size: 1.2rem; font-weight: 500;" target="_blank">
                    {{ customerInfo == null ? '' : customerInfo.name }}
                  </router-link>
                  <br />
                  <span>
                    {{ customerInfo == null ? '' : customerInfo.nationalNumber }}
                  </span>
                </div>
                <div v-else>
                  <span>
                    {{ customerInfo == null ? '' : customerInfo.name }}
                  </span>
                </div>
              </div>
            </b-col>
            <b-col md="6" class="align-self-center">
              <div class="row" v-if="$store.getters['Auth/checkPermission']('customer_details')">
                <div class="col-4"><span>{{ $t('Email') }}: </span> </div>
                <div class="col-8 text-right">
                  <span> {{ customerInfo == null ? '' : customerInfo.email }} </span>
                </div>
                <template v-if="$store.getters['Auth/currentStaff'].showPhoneNumbers || $store.getters['Auth/currentStaff'].showPhoneNumberForOneCustomer">
                  <div class="col-4"><span>{{ $t('Phone') }}:</span> </div>
                  <div class="col-8 text-right">
                    <span>{{ customerInfo == null ? '' : customerInfo.phone }} </span>
                  </div>
                </template>
                <div class="col-4"><span>{{ $t('CardNo') }}: </span></div>
                <div class="col-8 text-right">
                  <span>
                    {{ customerInfo == null ? '' : customerInfo.cardNo }}
                  </span>
                </div>
              </div>
            </b-col>
          </b-row>
        </tab-content-item>
        <tab-content-item :active="false" id="pills-appointmentInfo" aria-labelled-by="pills-appointmentInfo-tab">
          <div v-if="appointmentInfo && Object.keys(appointmentInfo).length > 0">
            <div class="row justify-content-center">
              <span><b>{{ $t('AppointmentID') }}:</b></span> &nbsp;
              <span>{{ appointmentInfo == null ? '' : appointmentInfo.id }} </span>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="d-flex align-items-center justify-content-around">
                  <span><b>{{ $t('Created') }}:</b></span>
                  <span>{{ appointmentInfo == null ? '' : appointmentInfo.created }} </span>
                </div>
                <div class="d-flex align-items-center justify-content-around">
                  <span><b>{{ $t('CreatedBy') }}:</b></span>
                  <span> {{ appointmentInfo == null ? '' : appointmentInfo.createdBy }} </span>
                </div>
              </div>
              <div class="col-6">
                <div class="d-flex align-items-center justify-content-around">
                  <span><b>{{ $t('Modified') }}:</b></span>
                  <span>{{ appointmentInfo == null ? '' : appointmentInfo.modified }} </span>
                </div>
                <div class="d-flex align-items-center justify-content-around">
                  <span><b>{{ $t('ModifiedBy') }}:</b></span>
                  <span> {{ appointmentInfo == null ? '' : appointmentInfo.modifiedBy }} </span>
                </div>
              </div>
            </div>
          </div>
        </tab-content-item>
        <tab-content-item :active="false" id="pills-appointments" aria-labelled-by="pills-appointments-tab">
          <div v-if="customerAppointments && customerAppointments.length > 0">
            <table class="table table-sm table-head-bg-brand">
              <thead class="thead-inverse">
                <tr>
                  <th>{{ $t('Date') }}</th>
                  <th>{{ $t('Status') }}</th>
                  <th>{{ $t('Staff') }}</th>
                  <th>{{ $t('Services') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(app,index) in customerAppointments" :key=index>
                  <td>{{ app.startDate }}</td>
                  <td>{{ app.status }}</td>
                  <td>{{ app.staff }}</td>
                  <td>{{ app.services }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <b-alert :show="true" variant="light">
              <div class="iq-alert-icon">
                <font-awesome-icon icon="fa-regular fa-triangle-exclamation" size="lg" />
              </div>
              <div class="iq-alert-text">{{ $t('NoAppointmentYet') }}</div>
            </b-alert>
          </div>
        </tab-content-item>
        <tab-content-item :active="false" id="pills-payments" aria-labelled-by="pills-payments-tab">
          <div v-if="customerBills && customerBills.length > 0">
            <table class="table table-sm table-head-bg-brand">
              <thead class="thead-inverse">
                <tr>
                  <th>{{ $t('Date') }}</th>
                  <th>{{ $t('Content') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(p,index) in customerBills" :key=index>
                  <td>{{ p.date }}</td>
                  <td>{{ p.entities }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <b-alert :show="true" variant="light">
              <div class="iq-alert-icon">
                <font-awesome-icon icon="fa-regular fa-triangle-exclamation" size="lg" />
              </div>
              <div class="iq-alert-text">{{ $t('NoPaymentYet') }}</div>
            </b-alert>
          </div>
        </tab-content-item>
        <tab-content-item :active="false" id="pills-tags" aria-labelled-by="pills-tags-tab">
          <div v-if="customerTags && customerTags.length > 0">
            <a v-for="(tag, index) in customerTags"
               style="cursor: unset;background-color: #4cb0c5;color: #fff;height: 25px;line-height:0.5em;font-size: .85em;margin-left:0.5%;"
               class="btn" :key="index">
              {{tag.tag.name}}
            </a>
          </div>
          <div v-else>
            <b-alert :show="true" variant="light">
              <div class="iq-alert-icon">
                <font-awesome-icon icon="fa-regular fa-triangle-exclamation" size="lg" />
              </div>
              <div class="iq-alert-text">{{ $t('NoTagsYet') }}</div>
            </b-alert>
          </div>
        </tab-content-item>
      </tab-content>
    </template>
  </iq-card>
</template>

<script>
  import customerService from '../../services/customer'
  import appointmentService from '../../services/appointment'
  import billService from '../../services/bill'
  import multimediaService from '../../services/multimedia'

  import AutoComplete from '../shared/AutoComplete'
  import FastNewCustomer from '../customer/FastNewCustomer'
  import TabNav from '../xray/tab/tab-nav'
  import TabNavItems from '../xray/tab/tab-nav-items'
  import TabContent from '../xray/tab/tab-content'
  import TabContentItem from '../xray/tab/tab-content-item'

  export default {
    name: 'CustomerSelection',
    props: {
      componentId: String,
      componentName: String
    },
    components: {
      AutoComplete,
      FastNewCustomer,
      TabNav,
      TabNavItems,
      TabContent,
      TabContentItem
    },
    data() {
      return {
        customerId: '',
        customerInfo: {},
        profilePicture: {},
        customerAppointments: [],
        customerBills: [],
        customerTags: [],
        appointmentInfo: {},
        showBodyForCustomerInfo: false
      };
    },
    methods: {
      getCustomerInfo(customerId) {
        customerService.getCustomerInfo(customerId)
          .then(response => {
            this.customerInfo = response;
          });
      },
      getCustomerProfilePicture(customerId) {
        if (customerId && this.$store.getters['Auth/checkPermission']('multimedia_show')) {
          multimediaService.getCustomerProfilePicture(customerId)
            .then(response => {
              this.profilePicture = response;
            });
        }
      },
      getCustomerAppointments(id) {
        appointmentService.getCustomerAppointments(id, this.customerId)
          .then(response => {

            if (id && id.length > 0) {
              this.appointmentInfo = response.find(z => z.id == this.componentId);
            }
            else {
              this.customerAppointments = response.filter(z => z.id != this.componentId);
            }

            if (!this.showBodyForCustomerInfo)
              this.showBodyForCustomerInfo = true;
          });
      },
      getCustomerBills() {
        billService.getCustomerBills(this.customerId)
          .then(response => {
            this.customerBills = response.filter(z => z.id != this.componentId);

            if (!this.showBodyForCustomerInfo)
              this.showBodyForCustomerInfo = true;
          });
      },
      getCustomerTags() {
        customerService.getCustomerTags(this.customerId)
          .then(response => {
            this.customerTags = response;

            if (!this.showBodyForCustomerInfo)
              this.showBodyForCustomerInfo = true;
          });
      },
      closeCustomerInfo() {
        this.customerId = '';
        this.$bus.$emit('CustomerSelectionComponent_onSelected', this.customerId);
      },
      collapseTagsToggle() {
        if (!this.showBodyForCustomerInfo)
          this.showBodyForCustomerInfo = true;
        else
          this.showBodyForCustomerInfo = false;
      }
    },
    watch: {
      customerId: function (val) {
        if (val != null && val.length > 0) {
          this.customerId = val;
          this.getCustomerInfo(val);
          this.getCustomerProfilePicture(val);
        }
      },
    },
    created: function () {
      this.$bus.$on('CustomerSelectionComponent_onSelected', (value) => {
        this.customerId = value;
      });
    }
  }
</script>

<style>
  .customer-info-card {
    padding: 0px !important;
  }
</style>
